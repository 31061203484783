import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Partners">
            <Row>
                <Col>
                    <Link to="/">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <LinkCard to="/partners/badges" title="Badges" />
                <LinkCard to="/partners/standard-graphics" title="Standard Graphics" />
                <LinkCard to="/partners/templates" title="Templates" />
                <LinkCard to="/partners/press-releases" title="Press Releases &amp; Emails" />


            </Row>
        </Layout>
    )
}

export default IndexPage
